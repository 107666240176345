import React ,{useState, useCallback, useRef, useEffect, Suspense} from 'react';
import './App.scss';
// import './_base.scss';
import { useSpring, animated } from 'react-spring'

// import ImgGridSystem from './components/ImgGridSystem';
import Menu from './components/Menu';
import Password from './components/Password';
// import About from './components/About';
import SwarmGroup from './components/InteractiveBG/SwarmGroup';
import ReactGa from 'react-ga';

// import PortfolioCotent from './components/PortfolioContentV2';
import {Route, NavLink,Link, BrowserRouter,useHistory, Switch, HashRouter,withRouter} from 'react-router-dom';

// import styles from "./components/Password.module.scss";
// import SplitText from "react-pose-text";
// import TextFx from './components/TextFx'


const ImgGridSystem = React.lazy(()=>import('./components/ImgGridSystem'));
const PortfolioCotent = React.lazy(()=>import('./components/PortfolioContentV2'));
const About = React.lazy(()=>import('./components/About'));
const ErrorPage = React.lazy(()=>import('./components/ErrorPage'));
// const Password = React.lazy(()=>import('./components/Password'));
function App() {
 
  // let isAboutOpen =false;
  // function refreshPage() {
  //   window.location.reload(true);
  // }


  useEffect(()=>{
    ReactGa.initialize('UA-169847537-1')
    // const page = window.location.pathname;
    // const hash = window.location.hash;
    // console.log(hash)
    // ReactGa.pageview(window.location.pathname+ window.location.search);
    ReactGa.pageview(window.location.hash+ window.location.search);
    // ReactGa.pageview(page)
  })


    const mouse = useRef([0, 0])
    const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
    
    
    const [isAuthenticated, setAuthenticated] = useState(false);


    //-----in app
    // const [inputPW, setPW] = useState("")
    // const theSecret='314';
    let LandingPage, PotContent;
   
    

      // LandingPage =(
      //   <Suspense fallback={<h1>Loading</h1>}>    
      //   {/* {CheckPWnShow} */}
       
      //         {/* <Password PWstates={SetisPWRight} /> */}
      //         {/* <Password sendData={this.getPinState} /> */}
      //         <ImgGridSystem mouse={mouse}  />
      //         </Suspense>

      // )
      // theSecret='10'


      //------working in app
      // useEffect(() => {
      //   if(inputPW===theSecret){
      //   setAuthenticated(true);
      //   console.log("you are right")
      // } 
      // }, [inputPW]);
        
          
     
  

    // let isAuthenticated=false;
    
    // const saveAuthenticationHandler;
 
   
    // useEffect(()=>{
      
    //   const saveAuthenticationHandler=(enterAuthenticationStatus)=> {

    //   setAuthenticated(enterAuthenticationStatus);
    // }, [isAuthenticated]);
    // useEffect(()=>{
    const saveAuthenticationHandler=()=>{
     
      
      setAuthenticated(true);
      
    
    }
     
 
    // setAuthenticated(austate);
    if(isAuthenticated){

      PotContent=(
        <Suspense fallback='null'>  
          <PortfolioCotent  />
          
          </Suspense>

      )
      LandingPage =(
        <Suspense fallback={<h1>Loading</h1>}>    
        {/* {CheckPWnShow} */}
       
              {/* <Password PWstates={SetisPWRight} /> */}
              {/* <Password sendData={this.getPinState} /> */}
              <ImgGridSystem mouse={mouse}  />
              </Suspense>

      ) } else
     if(!isAuthenticated){

      PotContent=(
        <Suspense fallback='null'>  
        <Password onAuthentication={saveAuthenticationHandler} />
        </Suspense>
      )
      LandingPage= (
         <Password onAuthentication={saveAuthenticationHandler} />
          // <div className="projectContentContainer">  
          
          // {/* <p>Your input is {inputPW} </p> */}
          // {/* <br/> */}
          // {/* {pwResult} */}
          // {/* <br/> */}
          //     {/* <p>hello world</p> */}
          //     <div className={styles.pwBG}>
            
          //   <div className={styles.pwstyle}>
          //   <form > 
              
          //     <label >
          //     <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>
          //     password:
          //     </SplitText>
          //         <br/>
          //     <input type="text" 
          //            value={inputPW}
                    
          //            onChange={(e) => setPW(e.target.value)}
          //            />
          //     </label>
          //     {/* <input type="submit" /> */}
          // </form>
                
          //   </div>
                
          // </div>
  
          
    // </div>



      )

    }

  return (
    
    <div className="App"  onMouseMove={onMouseMove}>

      {/* <div className="menu"> 
          <div onClick={closeAboutPage} >most rencent project by Dan Ran</div>
        <div onClick={aboutPage}>about</div>
      </div> */}
        {/* <PortfolioBG /> */}
       
      <SwarmGroup mouse={mouse} />
        
      <Menu />
      
    <Switch>

    <Route path='/About' exact>
        <Suspense fallback={null}>   
          <About />
          <div className='footer'> <p> this website is built on React.js, Written by Dan Ran.</p></div>              
        </Suspense>
      </Route>
    <Route path={'/:id/:name'} exact>
         
    {PotContent}

          {/* <Suspense fallback='null'>  
          <PortfolioCotent  />
          
          </Suspense> */}
        
    </Route>
    
        
   
    
      <Route path='/' exact>
      {/* <Password onAuthentication={saveAuthenticationHandler} /> */}

      {LandingPage}
     
      </Route>
      
   
      <Route path="*">
      <Suspense fallback='null'>
        <ErrorPage />        
      </Suspense>
          </Route>
    
    </Switch>
      

    {/* <Password /> */}
    </div>
    
   
  );
}

export default withRouter(App);
