import React, {useEffect, useState,useCallback, useRef,Suspense} from 'react';
import styles from "./Password.module.scss";
import SplitText from "react-pose-text";
import TextFx from './TextFx'
import hash from 'object-hash' 

import ReactDOM from 'react-dom';

// const ImgGridSystem = React.lazy(()=>import('./ImgGridSystem'));


const Password=(props)=>{


const [inputPW, setPW] = useState("")
const [trPW, settrPW]=useState("")

const handleSubmit = (event) => {
    event.preventDefault();
    
    alert(`The name you entered was: ${inputPW}`)
  }
//   props.PWstates(false);
const thes='11ed92d3ffa619d210ce8d24c7e8f427ec4c067e';
let pwResult, isPWRight;
 useEffect(() => {
    if(hash(inputPW)===thes){
      // if(hash(inputPW)!=thes){
        
            props.onAuthentication();
            console.log("you are right")
            
    } 
    
        
  }, [inputPW]);


return (

<div className="projectContentContainer">  
          
        {/* <p>Your input is {inputPW} </p> */}
        {/* <br/> */}
        {/* {pwResult} */}
        {/* <br/> */}
            {/* <p>hello world</p> */}
            <div className={styles.pwBG}>
          
          <div className={styles.pwstyle}>
          <form onSubmit={handleSubmit}> 
            
            <label >
            <SplitText initialPose="exit" pose="enter" charPoses={TextFx}>
            password:
            </SplitText>
                <br/>
            <input type="text" 
                   value={inputPW}
                   onChange={(e) => setPW(e.target.value)}
                   />
            </label>
            {/* <input type="submit" /> */}
        </form>
              
          </div>
              
        </div>

        
  </div>
);




}
export default Password;